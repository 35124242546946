import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaInfoCircle, FaImages, FaRegNewspaper, FaPhone } from 'react-icons/fa'; // Import icons
import Cart from './Cart';

const Home = () => {
  return (
    <div className="font-sans min-h-screen flex flex-col">
      {/* Header with contact info */}
    
      {/* Hero Section */}
      <div className="relative bg-gradient-to-r from-blue-500 to-blue-700 text-white py-20 flex-grow">
        <div className="container mx-auto text-center">
          <h4 className="text-4xl font-semibold mb-4 animate-bounce">We Care for Animals, You Can Too!</h4>
          <p className="mb-6 text-lg">Join us in giving animals the loving homes they deserve. Whether you're looking to adopt, buy, or find services for your pets, PashuPrani is your one-stop destination for everything animals. We connect you with a community that believes in ethical pet care and sustainability.</p>
          <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-bold py-2 px-6 rounded-lg transition duration-300">Contact Us</button>
        </div>
      </div>

      {/* Process Section */}
      <div className="py-20 bg-gray-50">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-bold">Process To Buy And Sell</h1>
          <p className="text-gray-600">Fill out the quick form and start the process of adopting or purchasing your next companion.</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 container mx-auto">
          {[
            { title: "Bull" },
            { title: "Cow" },
            { title: "Buffalo" },
            { title: "Dog" },
            { title: "Cat" },
          
            { title: "Birds" }
          ].map(({ title }) => (
            <div key={title} className="card bg-white shadow-lg rounded-lg overflow-hidden transform transition duration-300 hover:scale-105">
              <h3 className="text-center text-xl font-bold py-4">{title}</h3>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-gray-100 py-10">
        <div className="container mx-auto text-center">
          <h1 className="text-3xl font-bold mb-4">Adopt Your Favorite Pet</h1>
          <button className="bg-yellow-500 hover:bg-yellow-400 text-black font-bold py-2 px-6 rounded-lg transition duration-300">Adoption Form</button>
        </div>
      </div>

      <div className="bg-gray-500 py-10">
        <Cart/>

      </div>

      {/* Bottom Navbar for Small Devices */}
      <nav className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white shadow-lg md:hidden">
        <div className="flex justify-around p-4">
          <Link to="/" className="flex flex-col items-center">
            <FaHome className="text-2xl" />
            <span className="text-sm">Home</span>
          </Link>
          <Link to="/aboutus" className="flex flex-col items-center">
            <FaInfoCircle className="text-2xl" />
            <span className="text-sm">About Us</span>
          </Link>
          <Link to="/gallery" className="flex flex-col items-center">
            <FaImages className="text-2xl" />
            <span className="text-sm">Gallery</span>
          </Link>
          <Link to="/adoption-form" className="flex flex-col items-center">
            <FaRegNewspaper className="text-2xl" />
            <span className="text-sm">Adoption Form</span>
          </Link>
          <Link to="/contact" className="flex flex-col items-center">
            <FaPhone className="text-2xl" />
            <span className="text-sm">Contact Us</span>
          </Link>
        </div>
      </nav>
    </div>
  );
};

export default Home;

import React from 'react';
import Bull from '../assets/bull/bull.jpg'
import Dog from '../assets/bull/dog.jpg'
import Buffalo from '../assets/bull/Buffalo.jpg'

const Cart = () => {
  const cardData = [
    {
      image: Bull,
      title: 'Bull',
      description: 'Strong and hardworking farm animal. Perfect for agriculture.',
      whatsappNumber: '+918975205927', // Replace with the actual WhatsApp number
    },
    {
      image: Dog, // Replace with your image URLs
      title: 'Dog',
      description: 'Loyal companion, perfect for families and personal security.',
      whatsappNumber: '+0987654321', // Replace with the actual WhatsApp number
    },
    {
        image: Buffalo, // Replace with your image URLs
        title: 'Buffalo',
        description: 'Gentle and reliable, ideal for dairy farming and heavy-duty tasks.',
        whatsappNumber: '0987654321', // Replace with the actual WhatsApp number
      },
  ];

  return (
    <div className="container mx-auto py-10 px-5">
      <h1 className="text-3xl text-white font-bold text-center mb-10">Our Featured Animals</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
        {cardData.map((card, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg overflow-hidden hover:shadow-2xl transition duration-300"
          >
          <img
  src={card.image}
  alt={card.title}
  style={{
    width: '100%',
    height: '380px', // Adjust height as needed
    objectFit: 'cover',
  }}
/>
            <div className="p-6">
              <h2 className="text-xl font-bold mb-2">{card.title}</h2>
              <p className="text-gray-700">{card.description}</p>
              <a
                href={`https://wa.me/${card.whatsappNumber}`}
                target="_blank"
                rel="noopener noreferrer"
                className="mt-4 flex items-center text-green-500 font-semibold hover:underline"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" // WhatsApp logo URL
                  alt="WhatsApp Logo"
                  className="w-6 h-6 mr-2"
                />
                Contact on WhatsApp
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cart;

import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Home from "../pages/Home";
import Login from "../pages/Login";
import Forget from "../pages/Forget";
import Signup from "../pages/Signup";
import About from "../pages/About"
import ApplicationForm from "../pages/ApplicationForm";
import Contact from "../pages/Contact";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: '',
                element: <Home />
            },
            {
                path:'login',
                element:<Login/>
            },
            {
                path:'forget',
                element:<Forget/>
                
            },
            {
                path:'signup',
                element:<Signup/>
            },
            {
                path:'aboutus',
                element:<About/>
            },
            {
                path:'adoption-form',
                element:<ApplicationForm/>
            },
            {
                path:'contact',
                element:<Contact/>
            }
        ]
    }
])

export default router;